<template>
    <div>
        <div v-if="isArabic" class="marketo-form ar">
            <Typography class="title" variant="h3-display" as="h3">{{ $t('إشترك اليوم') }}</Typography>
            <Typography class="description" variant="body-display-small" as="p">{{ formDescriptionArabic }}</Typography>
            <form id="mktoForm_1014"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>شكرا لك</strong>
                <p>
                    الرجاء العودة إلى رسالة في بريدكم الإلكتروني تحوي رابطاً إلى موقع كارنيغي carnegiendowment.org، حيث
                    يمكنكم تعديل موادكم المفضَّلة.
                </p>
            </div>
        </div>
        <div v-else-if="isBeirut" class="marketo-form">
            <Typography class="title" variant="h3-display" as="h3">{{ $t('Subscribe Today') }}</Typography>
            <Typography class="description" variant="body-display-small" as="p"
                >{{ formDescriptionBeirutEnglish }}
            </Typography>
            <form id="mktoForm_1001"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>Thank you!</strong>
                <p>Check your email for details on your request.</p>
            </div>
        </div>
        <div v-else class="marketo-form">
            <Typography class="title" variant="h3-display" as="h3">{{ $t('Subscribe Today') }}</Typography>
            <Typography class="description" variant="body-display-small" as="p">{{
                formDescriptionDCEnglish
            }}</Typography>
            <form id="mktoForm_1001"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>Thank you!</strong>
                <p>Check your email for details on your request.</p>
            </div>
        </div>
    </div>
</template>

<script setup>
const { currentCenter } = useCenters();
const props = defineProps({
    formContextData: {
        type: Object,
    },
    formDescriptionArabic: {
        type: String,
        default: 'تسجّلوا لتلقي رسائل إلكترونية من مركز كارنيغي للشرق الأوسط',
    },
    formDescriptionDCEnglish: {
        type: String,
        default:
            'Sign up to receive emails from the Carnegie Middle East Program, including our monthly newsletter MENA Transitions!',
    },
    formDescriptionBeirutEnglish: {
        type: String,
        default:
            'Sign up to receive emails from the Malcolm H. Kerr Carnegie Middle East Center, including our monthly newsletter MENA Transitions!',
    },
});
const { locale } = useLocale();
const isArabic = computed(() => {
    return locale._value === 'ar';
});
const isBeirut = computed(() => {
    return currentCenter.value.slug === 'middleEast';
});
function formMENADCEn() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'MENATransitions Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ programAffiliation: 'middleEast' });
            form.addHiddenFields({ englishNewsletterAll: 'menaTransitions' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formMENABeirutEn() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'MENATransitions Beirut Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'beirut' });
            form.addHiddenFields({ programAffiliation: 'middleEast' });
            form.addHiddenFields({ englishNewsletterAll: 'menaTransitions' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formMENAAr() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1014, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'MENATransitions Subscribe Mkto Ar' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'beirut' });
            form.addHiddenFields({ nonEnglishNewsletterAll: 'cmecArabic' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
onMounted(() => {
    if (locale._value === 'en') {
        if (isBeirut) {
            formMENABeirutEn();
        } else {
            formMENADCEn();
        }
    }
    if (locale._value === 'ar') {
        formMENAAr();
    }
});
</script>

<style lang="scss" scoped>
@import './././assets/styles/core/marketoForms';
</style>
